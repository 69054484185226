<template>
  <div>
    <div>provider : {{ provider }}</div>
    <div>code : {{ state.code }}</div>
    <div>redirectUri : {{ state.redirectUri }}</div>
    <div>authData : {{ state.authData }}</div>
  </div>
</template>
<script>
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
export default {
  props: {
    provider: null,
  },
  setup(props) {
    const route = useRoute();
    const state = reactive({
      code: null,
      redirectUri: route.path,
      authData: null,
    });
    onMounted(() => {
      const authCode = route.query.code;
      state.code = authCode;

      const data = {
        provider: props.provider,
        code: authCode,
        redirectUri: route.path,
      };
      window.opener.postMessage(data, "*");
      // window.close();
    });

    return { state };
  },
};
</script>
